import PropTypes from "prop-types";
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { Menu, Button, Form, Grid, Dropdown, Checkbox, Message, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import ReactTable from "react-table-6";
import _ from "react-lib/compat/lodashplus";

import CardLayout from "react-lib/apps/common/CardLayout";
import RightLabel from "react-lib/apps/common/RightLabel";
import ModSearchDoctorNote from "./ModSearchDoctorNote";
import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";
import { useIntl } from "react-intl";

var dataSelected = {
	order: null,
	appointment: null,
};
var isFilterAppointment = false;
const CardAppointmentRequestConsult = forwardRef((props, ref) => {
	const intl = useIntl();
	
	const isMounted = useRef(true)
	const [divisions, setDivisions] = useState([]);
	const [selectedDivision, setSelectedDivision] = useState(null);
	const [doctors, setDoctors] = useState([]);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [consultOrders, setConsultOrders] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [text, setText] = useState(DEFAULT_TEXT);
	const [selectTime, setSelectTime] = useState(TIME_BUTTON_NAME.NORMAL);
	const [cardState, setCardState] = useState(CONSULT_ORDER_STATE.CREATE);
	const [loading, setLoading] = useState(false);
	// Modal
	const [openDoctorNote, setOpenDoctorNote] = useState(false);
	const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
	// Error state
	const [requestError, setRequestError] = useState([]);
	const [isDivisionError, setIsDivisionError] = useState(false);
	const [isDoctorNoteError, setIsDoctorNoteError] = useState(false);

	const initialDivision = async () => {
		const data = await props.controller.getDivisionList();
		if (isMounted.current) {
			if (data.length > 0) {
				setDivisions([DEFAULT_CHOICE, ...data]);
			}
		}
	};

	const initialDoctor = async divisionId => {
		// Get doctor list with division
		const data = await props.controller.searchDoctorList(divisionId);
		if (isMounted.current) {
			if (data.length > 0) {
				setDoctors([DEFAULT_CHOICE, ...data]);
			} else {
				setDoctors([]);
			}
			// Clear doctor
			setSelectedDoctor(null);
		}
		return data;
	};

	const initialConsultOrder = async patientId => {
		const data = await props.controller.getConsultOrder(patientId);
		if (isMounted.current) {
			setConsultOrders(data);
			setLoading(false);
		}
	};

	const initialNewConsultOrder = async patientId => {
		const data = await props.controller.getNewConsultOrder(patientId);
		if (isMounted.current) {
			setConsultOrders(data);
			setLoading(false);
		}
	};

	/**
	 * Refresh consult order
	 * @param {*} isFilter
	 */
	const refreshConsultOrder = isFilter => {
		let patientId = props.patientId;
		if (isFilter) {
			initialNewConsultOrder(patientId);
		} else {
			initialConsultOrder(patientId);
		}
	};

	/**
	 * Load appointment
	 * - Get appointment with app ID
	 * - Get consult order with order ID
	 * @param {*} appointmentId
	 */
	const loadAppointment = async appointmentId => {
		var appointmentModel = null;
		var orderModel = null;
		const appointment = await props.controller.getAppointmentWithId(appointmentId);
		// console.log("... appointment: ", appointment);
		if (isMounted.current) {
			if (appointment) {
				if (appointment.status != STATUS_REQUESTED) {
					// Disable add, edit, delete
					setCardState(CONSULT_ORDER_STATE.READONLY);
				} else {
					setCardState(CONSULT_ORDER_STATE.EDIT);
				}

				// Chain to fetch appointment consult order
				var children = appointment.children;
				if (children != {} && children.length > 0) {
					const order = await props.controller.getConsultOrderWithId(children[0].id);
					// console.log("... order: ", order);
					if (isMounted.current) {
						if (order) {
							appointmentModel = appointment;
							orderModel = order;
						}
					}
				}
			}
		}

		return [appointmentModel, orderModel];
	};

	/**
	 * Handle 'Checkbox' for filter appointment
	 * @param {*} event
	 * @param {*} data
	 */
	const handleFilterAppointment = (event, data) => {
		isFilterAppointment = data.checked;
		refreshConsultOrder(isFilterAppointment);
	};

	/**
	 * Handle 'Time' Button
	 * @param {*} event
	 * @param {*} name
	 */
	const handleSelectTime = (event, { name }) => {
		setSelectTime(name);
	};

	const getTimeColor = name => {
		var tColor = "black";
		if (name === selectTime) {
			tColor = "purple";
		}
		return tColor;
	};

	const changeDivision = async (event, data) => {
		let targetValue = data.value;
		var selected = null;
		for (var dv of divisions) {
			if (dv.value === targetValue) {
				selected = dv;
				break;
			}
		}

		if (selected) {
			if (selected.id != -1) {
				// Get doctor list with division
				initialDoctor(selected.id);
				// Handle division error
				if (isDivisionError) {
					setIsDivisionError(false);
				}
			}
		}
		setSelectedDivision(selected);
	};

	const changeDoctor = async (event, data) => {
		let targetValue = data.value;
		for (var dt of doctors) {
			if (dt.value === targetValue) {
				setSelectedDoctor(dt);
				break;
			}
		}
	};

	const changeText = (event, { name, value }) => {
		setText({
			...text,
			[name]: value,
		});
		// Handle doctor note error
		if (name === TEXT_NAME.NOTE_TO_DOCTOR) {
			if (isDoctorNoteError && value != "") {
				setIsDoctorNoteError(false);
			}
		}
	};

	/**
	 * Handle 'Selected DoctorNote' event from ModSearchDoctorNote
	 * @param {*} notes
	 */
	const handleSelectDoctorNote = notes => {
		var tDoctorNote = text[TEXT_NAME.DOCTOR_NOTE];
		if (tDoctorNote !== "") {
			tDoctorNote += "\n";
		}
		tDoctorNote += notes.join("\n");
		setText({
			...text,
			[TEXT_NAME.DOCTOR_NOTE]: tDoctorNote,
		});
	};

	/**
	 * Handle select consult order from Table
	 * @param {*} data
	 */
	const handleSelectConsultOrder = async data => {
		console.log("data: ", data);
		if (selectedRow && selectedRow === data.id) {
			return;
		}

		setLoading(true);
		if (requestError.length > 0) {
			// Clear error
			setRequestError([]);
			setIsDivisionError(false);
			setIsDoctorNoteError(false);
		}
		// Set selected row
		setSelectedRow(data.id);
		const [app, order] = await loadAppointment(data.id);
		if (!app || !order) {
			return;
		}

		if (!isMounted.current) {
			return;
		}

		dataSelected.order = order;
		dataSelected.appointment = app;
		setSelectTime(order.urgency);
		setText({
			...text,
			[TEXT_NAME.NOTE_TO_DOCTOR]: order.note,
			[TEXT_NAME.NOTE_TO_NURSE]: app.note_to_nurse,
			[TEXT_NAME.NOTE_TO_PATIENT]: app.note_to_patient,
			[TEXT_NAME.DOCTOR_NOTE]: app.doctor_note,
		});
		// Initial division and doctor
		var selected = null;
		for (var dv of divisions) {
			if (dv.id === order.division) {
				selected = dv;
				break;
			}
		}
		if (selected) {
			if (selected.id != -1) {
				// Get doctor list with division
				const data = await initialDoctor(selected.id);
				if (!isMounted.current) {
					return;
				}
				let targetDoctor = order.consulted_doctor;
				if (targetDoctor) {
					for (var doctor of data) {
						if (doctor.id === targetDoctor) {
							setSelectedDoctor(doctor);
							break;
						}
					}
				}
			}
		}

		setSelectedDivision(selected);
		setLoading(false);
	};

	/**
	 * Handle 'Clear' Button
	 */
	const handleClear = () => {
		setSelectedDivision(null);
		setSelectedDoctor(null);
		setText(DEFAULT_TEXT);
		setSelectTime(TIME_BUTTON_NAME.NORMAL);
		setSelectedRow(null);
		setCardState(CONSULT_ORDER_STATE.CREATE);
		// Clear error
		setRequestError([]);
		setIsDivisionError(false);
		setIsDoctorNoteError(false);
	};

	/**
	 * Handle delete consult order
	 */
	const handleDeleteConsultOrder = async () => {
		setOpenConfirmDelete(false);
		setLoading(true);
		let app = dataSelected.appointment;
		if (selectedRow && app && selectedRow === app.id) {
			const isSuccess = await props.controller.deleteConsultOrder(app);
			if (!isMounted.current) {
				return
			}
			if (isSuccess) {
				toast.success("ลบนัดหมายสำเร็จ");
				// Reload order
				handleClear();
				refreshConsultOrder(isFilterAppointment);
				return;
			}
		}

		setLoading(false);
		toast.error("เกิดข้อผิดพลาดในการลบนัดหมาย");
	};

	/**
	 * Handle
	 */
	const handleUpdateConsultOrder = async () => {
		let pass = _checkRequirementField();
		if (!pass) {
			return;
		}

		var order = dataSelected.order;
		var app = dataSelected.appointment;
		if (order && app && selectedRow && selectedRow === app.id) {
			setLoading(true);
			// Prepare data
			order = {
				...order,
				division: selectedDivision.id,
				consulted_doctor: selectedDoctor ? selectedDoctor.id : "",
				note: text[TEXT_NAME.NOTE_TO_DOCTOR],
				urgency: selectTime,
			};
			app = {
				...app,
				doctor: selectedDoctor ? selectedDoctor.id : "",
				note_to_nurse: text[TEXT_NAME.NOTE_TO_NURSE],
				note_to_patient: text[TEXT_NAME.NOTE_TO_PATIENT],
				doctor_note: text[TEXT_NAME.DOCTOR_NOTE],
			};
			const isSuccess = await props.controller.updateConsultOrder(order, app);

			if (isSuccess) {
				toast.success("บันทึกนัดหมายสำเร็จ");
				// Reload order
				handleClear();
				refreshConsultOrder(isFilterAppointment);
				return;
			}
		}

		setLoading(false);
		toast.error("เกิดข้อผิดพลาดในการบันทึกนัดหมาย");
	};

	/**
	 * Handle 'Request Appointment' Button
	 */
	const handleRequestAppointment = async () => {
		let pass = _checkRequirementField();
		if (!pass) {
			return;
		}

		setLoading(true);
		// Request Appointment
		const isSuccess = await props.controller.requestConsultOrder(
			props.patientId,
			props.encounterId,
			selectedDivision.id,
			selectedDoctor ? selectedDoctor.id : "",
			selectTime,
			text[TEXT_NAME.NOTE_TO_DOCTOR],
			text[TEXT_NAME.NOTE_TO_NURSE],
			text[TEXT_NAME.NOTE_TO_PATIENT],
			text[TEXT_NAME.DOCTOR_NOTE]
		);
		if (isMounted.current) {
			if (isSuccess) {
				// Reload order
				handleClear();
				refreshConsultOrder(isFilterAppointment);
			} else {
				setLoading(false);
			}
		}
	};

	const _checkRequirementField = () => {
		var error = [];
		var errDivision = false;
		var errDoctorNote = false;
		// Check division
		if (!selectedDivision || selectedDivision.id === -1) {
			error.push('กรุณาระบุค่าใน "แผนก"');
			errDivision = true;
		}
		// Check doctor note
		if (text[TEXT_NAME.NOTE_TO_DOCTOR] === "") {
			error.push('กรุณาระบุค่าใน "Note ถึงแพทย์ที่จะ Consult"');
			errDoctorNote = true;
		}

		setRequestError(error);
		setIsDivisionError(errDivision);
		setIsDoctorNoteError(errDoctorNote);
		if (error.length > 0) {
			return false;
		}

		return true;
	};

	const columns = [
		{
			Header: "Date",
			style: { whiteSpace: "unset" },
			accessor: "date",
			width: 120,
		},
		{
			Header: "ประเภท",
			style: { whiteSpace: "unset", width: "10%" },
			accessor: "type_name",
			width: 120,
		},
		{
			Header: "Detail",
			style: { whiteSpace: "unset", width: "60%" },
			Cell: props => {
				const data = props.original;
				return <div dangerouslySetInnerHTML={{ __html: data.summary_detail }} />;
			},
		},
		{
			Header: "เหตุผลการนัด",
			style: { whiteSpace: "unset", width: "10%" },
			accessor: "note_to_patient",
			width: 120,
		},
		{
			Header: "Status",
			style: { whiteSpace: "unset", width: "10%" },
			accessor: "status_name",
			width: 120,
		},
	];

	useImperativeHandle(ref, () => ({
		saveRequestConsult() {
			// Save request
		},
	}));

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])
	useEffect(() => {
		if (props.patientId != null && props.controller) {
			setLoading(true);
			initialConsultOrder(props.patientId);
			initialDivision();
			isFilterAppointment = false;
		}
	}, [props.patientId, props.controller]);

	return (
		<CardLayout
			titleText={"Consult"}
			headerColor="gray"
			closeable={props.closeable}
			toggleable={props.toggleable}
			hideHeaderIcon={false}
			loading={loading}
			onClose={props.onClose}
		>
			{/* Add doctor note */}
			<Modal open={openDoctorNote} onClose={() => setOpenDoctorNote(false)}>
				<ModSearchDoctorNote
					controller={props.modSearchDoctorNoteController}
					onClose={() => setOpenDoctorNote(false)}
					onSelectNote={handleSelectDoctorNote}
					languageUX={props.languageUX}
				/>
			</Modal>

			<ModConfirmCNMI
				openModal={openConfirmDelete}
				titleName={"ยืนยัน"}
				content={<p>{intl.formatMessage({ id: "ต้องการลบรายการนัดหมายใช่หรือไม่" })}</p>}
				onApprove={handleDeleteConsultOrder.bind(this)}
				onDeny={() => {
					setOpenConfirmDelete(false);
				}}
				onCloseWithDimmerClick={() => {
					setOpenConfirmDelete(false);
				}}
			/>

			<Grid>
				<Grid.Row columns={2}>
					<Grid.Column>
						<Message error={true} hidden={requestError.length === 0}>
							<Message.List items={requestError} />
						</Message>
						<Form>
							<Form.Group inline style={styles.marginComponentStyle}>
								<Form.Field width={2}>
									<RightLabel>{intl.formatMessage({ id: "แผนก" })}</RightLabel>
								</Form.Field>
								<Form.Field width={7}>
									<Dropdown
										className="fluidDropdown"
										search
										selection
										error={isDivisionError}
										placeholder=""
										options={divisions}
										value={selectedDivision ? selectedDivision.value : ""}
										onChange={changeDivision.bind(this)}
									/>
								</Form.Field>
								<Form.Field width={5}>
									<Menu inverted widths={3} size={"small"}>
										<Menu.Item
											name={TIME_BUTTON_NAME.TODAY}
											active={selectTime === TIME_BUTTON_NAME.TODAY}
											color={getTimeColor(TIME_BUTTON_NAME.TODAY)}
											onClick={handleSelectTime.bind(this)}
										>
											วันนี้
										</Menu.Item>
										<Menu.Item
											name={TIME_BUTTON_NAME.NOW}
											active={selectTime === TIME_BUTTON_NAME.NOW}
											color={getTimeColor(TIME_BUTTON_NAME.NOW)}
											onClick={handleSelectTime.bind(this)}
										>
											เร็ว
										</Menu.Item>
										<Menu.Item
											name={TIME_BUTTON_NAME.NORMAL}
											active={selectTime === TIME_BUTTON_NAME.NORMAL}
											color={getTimeColor(TIME_BUTTON_NAME.NORMAL)}
											onClick={handleSelectTime.bind(this)}
										>
											ปกติ
										</Menu.Item>
									</Menu>
								</Form.Field>
							</Form.Group>

							<Form.Group inline style={styles.marginComponentStyle}>
								<Form.Field width={2}>
									<RightLabel>{intl.formatMessage({ id: "แพทย์" })}</RightLabel>
								</Form.Field>
								<Form.Field width={7}>
									<Dropdown
										className="fluidDropdown"
										search
										selection
										placeholder=""
										options={doctors}
										value={selectedDoctor ? selectedDoctor.value : ""}
										onChange={changeDoctor.bind(this)}
									/>
								</Form.Field>
							</Form.Group>

							<Form.TextArea
								style={styles.marginComponentStyle}
								error={isDoctorNoteError}
								label={"Note ถึงแพทย์ที่จะ Consult"}
								name={TEXT_NAME.NOTE_TO_DOCTOR}
								value={text[TEXT_NAME.NOTE_TO_DOCTOR]}
								onChange={changeText.bind(this)}
							/>

							<Form.Group widths={"equal"} style={styles.marginComponentStyle}>
								<Form.TextArea
									label={"Note to Nurse"}
									name={TEXT_NAME.NOTE_TO_NURSE}
									value={text[TEXT_NAME.NOTE_TO_NURSE]}
									onChange={changeText.bind(this)}
								/>
								<Form.TextArea
									label={"เหตุผลการนัด (ข้อความนี้จะปรากฏในใบนัด)"}
									name={TEXT_NAME.NOTE_TO_PATIENT}
									value={text[TEXT_NAME.NOTE_TO_PATIENT]}
									onChange={changeText.bind(this)}
								/>
							</Form.Group>

							<Button
								style={{ width: "20%" }}
								floated={"right"}
								color={"green"}
								onClick={() => setOpenDoctorNote(true)}
							>
								เพิ่มคำแนะนำ
							</Button>
							<Form.TextArea
								style={styles.marginComponentStyle}
								label={"คำแนะนำในการปฏิบัติตัวเพิ่มเติม"}
								name={TEXT_NAME.DOCTOR_NOTE}
								value={text[TEXT_NAME.DOCTOR_NOTE]}
								onChange={changeText.bind(this)}
							/>

							<div style={styles.marginComponentStyle}>
								<Button style={{ width: "15%" }} color={"orange"} onClick={handleClear.bind(this)}>
									Clear
								</Button>
								{cardState === CONSULT_ORDER_STATE.CREATE && (
									<Button floated={"right"} color={"blue"} onClick={handleRequestAppointment.bind(this)}>
										Request Appointment
									</Button>
								)}
								{cardState === CONSULT_ORDER_STATE.EDIT && (
									<Button
										floated={"right"}
										color={"red"}
										onClick={() => {
											setOpenConfirmDelete(true);
										}}
									>
										Delete
									</Button>
								)}
								{cardState === CONSULT_ORDER_STATE.EDIT && (
									<Button floated={"right"} color={"yellow"} onClick={handleUpdateConsultOrder.bind(this)}>
										Edit Appointment
									</Button>
								)}
							</div>
						</Form>
					</Grid.Column>

					<Grid.Column>
						<Form>
							<Form.Group inline style={styles.marginComponentStyle}>
								<Form.Field width={10}>
									<RightLabel style={{ textAlign: "left" }}>{intl.formatMessage({ id: "รายการนัดหมาย Consult ผู้ป่วย" })}</RightLabel>
								</Form.Field>
								<Form.Field width={6}>
									<Checkbox label={"แสดงเฉพาะนัดหมายใหม่"} onClick={handleFilterAppointment.bind(this)}></Checkbox>
								</Form.Field>
							</Form.Group>

							<ReactTable
								style={{ height: "450px" }}
								noDataText="ไม่มีข้อมูล"
								showPagination={false}
								pageSize={consultOrders.length > 3 ? consultOrders.length : 3}
								columns={columns}
								data={consultOrders}
								getTrProps={(state, rowInfo, column, instance) => {
									if (rowInfo && rowInfo.row) {
										return {
											onClick: (event, handleOriginal) => {
												if (handleOriginal) {
													handleOriginal();
												}
												handleSelectConsultOrder(rowInfo.original);
											},
											className: rowInfo.original.id === selectedRow ? "blueSelectedRow" : "",
										};
									} else {
										return {};
									}
								}}
							/>
						</Form>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</CardLayout>
	);
});

const CONSULT_ORDER_STATE = {
	CREATE: "create",
	EDIT: "edit",
	READONLY: "readonly",
};

const TEXT_NAME = {
	NOTE_TO_DOCTOR: "noteToDoctor",
	NOTE_TO_NURSE: "noteToNurse",
	NOTE_TO_PATIENT: "noteToPatient",
	DOCTOR_NOTE: "doctorNote",
};

const TIME_BUTTON_NAME = {
	TODAY: "A",
	NOW: "B",
	NORMAL: "C",
};

const styles = {
	marginComponentStyle: {
		marginBottom: "8px",
	},
};

const DEFAULT_TEXT = {
	[TEXT_NAME.NOTE_TO_DOCTOR]: "",
	[TEXT_NAME.NOTE_TO_NURSE]: "",
	[TEXT_NAME.NOTE_TO_PATIENT]: "",
	[TEXT_NAME.DOCTOR_NOTE]: "",
};
const DEFAULT_CHOICE = { id: -1, text: "", value: "" };
const STATUS_REQUESTED = 6;

CardAppointmentRequestConsult.defaultProps = {
	onClose: () => { },
	patientId: null,
	encounterId: null,
	controller: null,
	modSearchDoctorNoteController: null,
	closeable: true,
	toggleable: false
};

CardAppointmentRequestConsult.propTypes = {
	onClose: PropTypes.func,
	patientId: PropTypes.number,
	encounterId: PropTypes.number,
	controller: PropTypes.object,
	modSearchDoctorNoteController: PropTypes.object,
};

export default CardAppointmentRequestConsult;
