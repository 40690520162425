import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import { Button, Dropdown, Input, Form, Icon, Grid, Label, Checkbox, FormGroup, FormField, TextArea } from "semantic-ui-react";
import { toast } from "react-toastify";
import ReactTable from "react-table-6";
import RightLabel from "../common/RightLabel";
import TagsInput from "../common/TagsInput";
import _ from '../../../react-lib/compat/lodashplus';
import { useIntl } from "react-intl";

const SubICDDetail = React.forwardRef((props, ref) => {
	const intl = useIntl();
	const [typeOptionChoice, setTypeOptionChoice] = useState([]);
	const [ICDType, setICDType] = useState("");
	const [ICDSubType, setICDSubType] = useState("");
	const [ICDText, setICDText] = useState(props.initialData);
	const [detailText, setDetailText] = useState("");
	const [isShowSearchTable, setIsShowSearchTable] = useState(false);
	const [isShowDetail, setIsShowDetail] = useState(false);
	const [searchData, setSearchData] = useState([]);
	const [selected, setSelected] = useState({});
	const [medterm, setMedterm] = useState("");
	const tagsInputRef = useRef();

	React.useImperativeHandle(ref, () => ({
		getData: () => {
			return { ...ICDText, ...selected };
		}
	}))

	useEffect(() => {
    props.onChangeSubType?.(ICDSubType);
  }, [ICDSubType]);

	useEffect(() => {
		setICDText(props.initialData);
		setMedterm(props.initialData.medical_description);
		if (props.initialData.icd_code) {
			handleDetailLayout(props.initialData.icd_code)
			if (props.initialData.detail) {
				setDetailText(props.initialData.detail);
			}
		}
	}, [props.initialData]);

	/** Show / Hide Input detail */
	const handleDetailLayout = codeText => {
		var isDisplay = false;
		if ((codeText !== "") && (E_CODE.indexOf(codeText[0].toUpperCase()) != -1)) {
			isDisplay = true;
		}

		if (isDisplay !== isShowDetail) {
			if(!props.isShowDetail){
				setDetailText("")
			}
			setIsShowDetail(isDisplay);
		}
	}

	/** Handle onClick (Erase button) event */
	const handleEraseText = (e, data) => {
		setICDText(DEFAULT_DIAGNOSIS);
		setDetailText("")
		setSearchData([]);
		setIsShowSearchTable(false);
		setMedterm("");
		props.onErased();
	};


	const handleCheckboxChange = async (e, data) => {
		var prepare = { ...ICDText }
		prepare.active_disease = data.checked
		// console.log("handleCheckboxChange prepare ", prepare)
		setICDText(prepare);
		props.onChecked(data.checked)
	}

	/** Handle onChange (Input) event */
	const handleChangeText = async (e, data) => {
		if (data.value === "") {
			setICDText(DEFAULT_DIAGNOSIS);
			if(props.isShowDetail){
				setDetailText("")
			}
			setSearchData([]);
			setIsShowSearchTable(false);
			setMedterm("");
			return;
		}

		if (data.name === DIAGNOSIS_NAME.medical_description) {
			setMedterm(data.value);
		}

		// console.log("handleChangeText data ", data)

		// Don't update medical term
		if (props?.card === "CardAdmitOrder" ){
			if (selected) {
				setSelected({})
				setDetailText("")
				setSearchData([]);
				props.onErased();
			}

			const newText = {
				...DEFAULT_DIAGNOSIS,
				active_disease: ICDText.active_disease,
				[data.name]: data.value,
			};
			// console.log("handleChangeText newText", newText)
			setICDText(newText);
		}
		else{
			if (data.name !== DIAGNOSIS_NAME.medical_description) {

				if (selected) {
					setSelected({})

					if(!props.isShowDetail){
						setDetailText("")
					}

					setSearchData([]);
					props.onErased();
				}

				const newText = {
					...DEFAULT_DIAGNOSIS,
					active_disease: ICDText.active_disease,
					[data.name]: data.value,
				};
				// console.log("handleChangeText newText", newText)
				setICDText(newText);
			} else {
				handleTagTextMedicalDescription(data.value)
			}
		}


		// Show / Hide Input detail
		if (data.name === DIAGNOSIS_NAME.icd_code) {
			handleDetailLayout(data.value)
		}

		// Search data
		if (data.value.length > 2) {
			// Don't search medical_description if icd_code existed sommmmmmm
			if ((data.name === DIAGNOSIS_NAME.medical_description) && (ICDText.icd_code)) {
				return;
			}

			var result = [[], null]
			switch (data.name) {
				case DIAGNOSIS_NAME.icd_code:
					result = await props.controller.searchWithICDCode(data.value);
					break;
				case DIAGNOSIS_NAME.icd_term:
					result = await props.controller.searchWithICDTerm(data.value);
					break;
				case DIAGNOSIS_NAME.medical_description:
					result = await props.controller.searchWithMedTerm(data.value);
					break;
				default:
					break;
			}

			let dataList = result[0];
			let dataError = result[1];
			if (dataError) { toast.error(dataError); }
			else { setSearchData(dataList); }
			setIsShowSearchTable(true);
		} else {
			setIsShowSearchTable(false);
		}
	};

	const handleTagTextMedicalDescription = (data) => {
		const newText = {
			...ICDText,
			[DIAGNOSIS_NAME.medical_description]: ICDText[DIAGNOSIS_NAME.medical_description] + "," + data.toString(),
		};
		if (selected && (!_.isEmpty(selected))) {
			var targetSelected = {
				...selected,
				"medterm": ICDText[DIAGNOSIS_NAME.medical_description] + "," + data.toString()
			}
			// console.log("handleTagTextMedicalDescription targetSelected: ", targetSelected)
			props.onSelected?.({
        ...targetSelected,
        type: ICDSubType,
        active_disease: ICDText.active_disease,
      });
		}

	}

	/** Handle TagsInput onTagsChange */
	const handleMedicalDescription = (data) => {
		// console.log(" handleMedicalDescription data:", data)
		const updateMedTerm = data.join(",");
		const newText = {
			...ICDText,
			[DIAGNOSIS_NAME.medical_description]: updateMedTerm,
		};
		setICDText(newText);
		setMedterm(updateMedTerm);

		var targetSelected: Record<string, any> = {}
		if (selected && (!_.isEmpty(selected))) {
			targetSelected = {
				...selected,
				"medterm": data
			}
		} else {
			if (newText.icd_code === "") {
				// Do nothings
				return;
			}

			targetSelected = {
				"icd10_id": newText.icd10 ? newText.icd10 : null,
				"icdcode": newText.icd_code ? newText.icd_code : "",
				"icdterm": newText.icd_term ? newText.icd_term : "",
				"medterm": data,
				"detail": newText.detail ? newText.detail : "",
				"active_disease": newText.active_disease ? true : false
			}
		}

		// Update selected
		// setSelected(targetSelected);
		if (data.length === 0) {
			setICDText(DEFAULT_DIAGNOSIS);
			setDetailText("")
			setSearchData([]);
			setIsShowSearchTable(false);
		} else {
			setSelected(targetSelected);
		}
		// console.log("handleMedicalDescription targetSelected: ",targetSelected)
		props.onSelected?.({
      ...targetSelected,
      type: ICDSubType,
      active_disease: targetSelected.active_disease,
    });
	}

	/** Handle onChange (Input name="detail") event */
	const handleChangeDetailText = (e, data) => {
		setDetailText(data.value)
		props.onDetailChange(data.value)
	}

	/** Handle onClick event */
	const handleSelected = async (data, isActive_disease) => {
		var newDiagnosis;
		// Case: Sublevel
		if (data.sublevel) {
			newDiagnosis = {
				...ICDText,
				icd_term: MSG_SELECT_SUBLEVEL,
			};
			const [dataList, dataError] = await props.controller.searchSublevelWithICDCode(data.icdcode);
			if (dataError) { toast.error(dataError); }
			else { setSearchData(dataList); }
		}
		// Case: Normal
		else {
			newDiagnosis = {
				medical_description: data.medterm,
				icd_term: data.icdterm,
				icd_code: data.icdcode,
				active_disease: isActive_disease
			};
			// Disable search table
			setIsShowSearchTable(false);
			// Show / Hide Input detail
			handleDetailLayout(data.icdcode)
			// Callback
			data.detail = detailText
			data.active_disease = isActive_disease
			// console.log("Selected data:", data)
			// console.log("Selected newDiagnosis: ", newDiagnosis)
			setSelected(data)
			props.onSelected?.({
        ...data,
        type: ICDSubType,
        active_disease: data.active_disease,
      });
		}

		setICDText(newDiagnosis);
	};

	const handleICDSubType = (e, data) => {
		setICDSubType(data.value);
	}

	/** Fetch sub type */
	const getSubType = async () => {
		var model = null;
		switch (ICDType) {
			case ICD_TYPE.ICD10:
				model = "core.Diagnosis"
				break;
			case ICD_TYPE.ICD9CM:
				model = "core.Procedure"
				break;
			case ICD_TYPE.OPERATING_ICD9CM:
				model = "ORM.OperatingProcedure"
				break;
			default:
				break;
		}

		if (model) {
			var result = [];
			let params = { model: model, field: "type", nameAsId: true }
			const [response, error, network] = await props.controller.getChoices(params);
			if (error) {
				toast.error(error);
			} else {
				if (ICDType === ICD_TYPE.ICD10) {
					result = response.filter(item => {
						if (props.isIPDProvisional) {
							return item.id.startsWith('PROVISIONAL_SECONDARY_')
						} else {
							return item.id.startsWith('SECONDARY_')
						}
					});
				}
			}

			// Initial Data
			if (result.length > 0) {
				setICDSubType(result[0].value)
			}
			setTypeOptionChoice(result);
		}
	}

	/** For tags input */
	const getTagsList = (data) => {
		var result = [];
		if (data) {
			let arr = data.split(",");
			for (var str of arr) {
				result.push(str);
			}
		}
		return result;
	}

	useEffect(() => {
		if (!ICDType) { return; }
		if (!props.showSubType) { return; }
		// Get sub type
		getSubType();

	}, [ICDType]);

	useEffect(() => {
		if (props.controller) {
			setICDType(props.controller.getICDType())
		}
	}, [props.controller])

	useEffect(() => {
		setICDText(props.initialData);
		// console.log(" props.initialData", props.initialData)
		if (props.initialData.icd_code) {
			handleDetailLayout(props.initialData.icd_code)
			if (props.initialData.detail) {
				setDetailText(props.initialData.detail);
			}
		}

	}, [props.initialData]);

	const columns = [
		{
			id: "medical_term",
			minWidth: 480,
			accessor: "medterm",
		},
		{
			id: "icd10_term",
			minWidth: 500,
			Cell: props => {
				const { icdterm, sublevel } = props.original;
				return (
					<div>
						<p style={{ color: sublevel ? "red" : "black" }}>{icdterm}</p>
					</div>
				);
			},
		},
		{
			id: "icd10_code",
			Cell: props => {
				const { icdcode, sublevel } = props.original;
				return (
					<div>
						<p style={{ color: sublevel ? "red" : "black" }}>{icdcode}</p>
					</div>
				);
			},
		},
	];

	console.log("ICDText: ", ICDText)
	console.log(" SubICDDetail props", props)
	return (
    <div style={{ width: "100%", marginTop: props.marginTop, marginBottom: props.marginBottom }}>
      {props.showSubType && (
        <Form.Field inline style={{ marginBottom: "12px" }}>
          <Label
            style={{ width: "8%", color: "black", backgroundColor: "transparent", textAlign: "right" }}
            size={"large"}>ประเภท<span aria-hidden="true" style={{ color: "red" }}>*</span></Label>
          <Dropdown
            style={{ width: "52%" }}
            search
            selection
            options={typeOptionChoice}
            value={ICDSubType}
            onChange={handleICDSubType}
          />
        </Form.Field>
      )}

      {/* ui ipad */}
      <Form>
        <FormGroup inline style={{ margin: "0px 0 0 14px" }}>
          <FormField inline width={props.hideActiveDisease ? 6 : 4}>
            <div style={{ fontWeight: "bold", minWidth: "6.25rem", maxWidth: "6.25rem" }}>Medical Term</div>
            {props?.card !== "CardAdmitOrder" ? (
              <TagsInput
								id={props.id ? `SubICDDetail-TagsInput-MedTerm-${props.id}` : "SubICDDetail-TagsInput-MedTerm"}
                ref={tagsInputRef}
                inputSize={props.inputSize}
                // tags={getTagsList(ICDText.medical_description)}
								tags={getTagsList(medterm)}
                disabled={props.isReadOnly}
                inputName="medical_description"
                onChange={handleChangeText}
                onTagsChange={handleMedicalDescription}
              />
            ) : (
              <Input
								id={props.id ? `SubICDDetail-Input-MedTerm-${props.id}` : "SubICDDetail-Input-MedTerm"}
                size={props.inputSize}
                style={{ width: "100%" }}
                name="medical_description"
                // value={ICDText.medical_description}
								value={medterm}
                disabled={props.isReadOnly}
                onChange={handleChangeText}
              />
            )}
          </FormField>
          <FormField inline width={props.hideActiveDisease ? 6 : 4}>
            <div style={{ fontWeight: "bold", minWidth: "max-content" }}>{ICDType} Term</div>
            <Input
							id={props.id ? `SubICDDetail-Input-IcdTerm-${props.id}` : "SubICDDetail-Input-IcdTerm"}
              size={props.inputSize}
              style={{ width: "100%" }}
              name="icd_term"
              value={ICDText.icd_term}
              disabled={props.isReadOnly}
              onChange={handleChangeText}
            />
          </FormField>
          <FormField inline width={4}>
            <div style={{ fontWeight: "bold", minWidth: "max-content" }}>{ICDType} Code</div>
            <Input
							id={props.id ? `SubICDDetail-Input-IcdCode-${props.id}` : "SubICDDetail-Input-IcdCode"}
              size={props.inputSize}
              style={{ width: "100%" }}
              name="icd_code"
              value={ICDText.icd_code}
              disabled={props.isReadOnly}
              onChange={handleChangeText}
            />
          </FormField>
          <FormField inline width={2} style={{ display: props.hideActiveDisease ? "none" : "" }}>
            <div style={{ display: "flex" }}>
							<Checkbox
								id="SubICDDetail-Checkbox-ActiveDisease"
								checked={ICDText.active_disease}
								onChange={handleCheckboxChange}
							/>
							<span style={{ color: "#0f3aff", fontWeight: "bold", marginLeft: "10px" }}>active disease</span>
						</div>
          </FormField>
          <FormField inline width={2} style={{ display: props.hideActiveDisease ? "none" : "" }}>
						<Button
							id="SubICDDetail-Button-Erase"
							icon color="teal"
							floated="right"
							disabled={props.isReadOnly}
							onClick={handleEraseText}>
							<Icon name="erase" />
						</Button>

            {props.isShowDeleteButton && (
              <Button
								id="SubICDDetail-Button-Delete"
                icon
                color="red"
                floated="right"
                disabled={props.isReadOnly}
                onClick={() => props.onDelete()}
              >
                <Icon name="minus" />
              </Button>
            )}
          </FormField>
        </FormGroup>
        <FormGroup inline>
          <FormField inline width={16}>
            {isShowSearchTable && (
              <ReactTable
								className="--rt-tbody-overflow-y-hidden"
                style={{ height: "150px", width: "100%", marginTop: "12px", marginBottom: "4px" }}
                noDataText="ไม่มีข้อมูล"
                TheadComponent={(props) => null}
                showPagination={false}
                defaultPageSize={150}
                minRows={5}
                columns={columns}
                data={searchData}
                getTrProps={(state, rowInfo, column, instance) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: (event, handleOriginal) => {
                        if (handleOriginal) {
                          handleOriginal();
                        }
                        // console.log("rowInfo.original", rowInfo.original)
                        handleSelected(rowInfo.original, ICDText.active_disease);
                      },
                    };
                  } else {
                    return {};
                  }
                }}
              />
            )}
          </FormField>
        </FormGroup>
      </Form>

      {/* <Grid>
				<Grid.Row verticalAlign={"middle"}>
					<Grid.Column style={{ width: props.labelFitContent ? "85px" : "8%", whiteSpace: "null" }} textAlign={"right"} >
						<Form.Field>
							<RightLabel style={STYLE.label}>Medical Term</RightLabel>
						</Form.Field>
					</Grid.Column>
					<Grid.Column style={{ width: props.labelFitContent ? "19%" : "24%" }}>
						<Form.Field style={{ padding: props.labelFitContent ? 0 : "" }}>
							{props?.card !== "CardAdmitOrder" ?
							<TagsInput
								ref={tagsInputRef}
								inputSize={props.inputSize}
								tags={getTagsList(ICDText.medical_description)}
								disabled={props.isReadOnly}
								inputName="medical_description"
								onChange={handleChangeText}
								onTagsChange={handleMedicalDescription}
							/>
							:
							<Input
								size={props.inputSize}
								style={{ width: "100%" }}
								name="medical_description"
								value={ICDText.medical_description}
								disabled={props.isReadOnly}
								onChange={handleChangeText}
							/>
							}
						</Form.Field>
					</Grid.Column>

					<Grid.Column style={{ width: props.labelFitContent ? "85px" : "7%", whiteSpace: "null" }} textAlign={"right"} >
						<RightLabel style={STYLE.label}>{ICDType} Term</RightLabel>
					</Grid.Column>
					<Grid.Column style={{ width: props.labelFitContent ? "19%" : "24%" }}>
						<Input
							size={props.inputSize}
							style={{ width: "100%" }}
							name="icd_term"
							value={ICDText.icd_term}
							disabled={props.isReadOnly}
							onChange={handleChangeText}
						/>
					</Grid.Column>

					<Grid.Column style={{ width: props.labelFitContent ? "85px" : "7%", whiteSpace: "null" }} textAlign={"right"} >
						<RightLabel style={STYLE.label}>{ICDType} Code</RightLabel>
					</Grid.Column>
					<Grid.Column style={{ width: "14%" }}>
						<Input
							size={props.inputSize}
							style={{ width: "100%" }}
							name="icd_code"
							value={ICDText.icd_code}
							disabled={props.isReadOnly}
							onChange={handleChangeText}
						/>
					</Grid.Column>

					<Grid.Column style={{ width: props.labelFitContent ? "160px" :"9%" }}>
						<div style={{ display: "flex" }}>
							<Checkbox
								checked={ICDText.active_disease}
								onChange={handleCheckboxChange}
							/>
							<span style={{ color: "#0f3aff", fontWeight: "bold", marginLeft: "10px", minWidth:"max-content" }}>active disease</span>
						</div>
					</Grid.Column>


					<Grid.Column style={{ width: "3%" }}>
						<Button
							icon color="teal"
							floated="right"
							disabled={props.isReadOnly}
							onClick={handleEraseText}>
							<Icon name="erase" />
						</Button>
					</Grid.Column>

					<Grid.Column style={{ width: "3%" }}>
						{props.isShowDeleteButton && (
							<Button
								icon color="red"
								floated="right"
								disabled={props.isReadOnly}
								onClick={() => props.onDelete()}>
								<Icon name="minus" />
							</Button>
						)}
					</Grid.Column>
				</Grid.Row>
			</Grid>

			{isShowSearchTable && (
				<ReactTable
					style={{ height: "150px", width: "100%", marginTop: "12px", marginBottom: "4px" }}
					noDataText="ไม่มีข้อมูล"
					TheadComponent={props => null}
					showPagination={false}
					defaultPageSize={150}
					minRows={5}
					columns={columns}
					data={searchData}
					getTrProps={(state, rowInfo, column, instance) => {
						if (rowInfo && rowInfo.row) {
							return {
								onClick: (event, handleOriginal) => {
									if (handleOriginal) {
										handleOriginal();
									}
									// console.log("rowInfo.original", rowInfo.original)
									handleSelected(rowInfo.original, ICDText.active_disease);
								},
							};
						} else {
							return {};
						}
					}}
				/>
			)} */}

      {/* แสดงตามเงื่อนไขหรือแสดงเสมอเมื่อส่ง props เข้ามา */}
      {(isShowDetail || props.isShowDetail) && (
        <Grid>
          <Grid.Column style={{ minWidth: "8rem", maxWidth: "8rem", whiteSpace: "null", paddingRight: 0 }} textAlign={"right"}>
            <Form.Field>
              <RightLabel style={STYLE.label}>{intl.formatMessage({ id: "สาเหตุเพิ่มเติม" })}</RightLabel>
            </Form.Field>
          </Grid.Column>

          <Grid.Column style={{ width: "64.75%" }}>
            <Form>
              <Form.Field>
                {props.isShowDetail ? (
                  <TextArea
										id="SubICDDetail-TextArea-Detail"
                    size={props.inputSize}
                    style={{ width: "100%" }}
                    name="detail"
                    value={detailText}
                    disabled={props.isReadOnly}
                    onChange={handleChangeDetailText}
                  />
                ) : (
                  <Input
										id="SubICDDetail-Input-Detail"
                    size={props.inputSize}
                    style={{ width: "100%" }}
                    name="detail"
                    value={detailText}
                    disabled={props.isReadOnly}
                    onChange={handleChangeDetailText}
                  />
                )}
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid>
      )}
    </div>
  );
});

const E_CODE = ["V", "W", "X", "Y"];
const MSG_SELECT_SUBLEVEL = "Please Select Sublevel";
const DIAGNOSIS_NAME = {
	medical_description: "medical_description",
	icd_term: "icd_term",
	icd_code: "icd_code",
	active_disease: "active_disease"
};

const DEFAULT_DIAGNOSIS = {
	medical_description: "",
	icd_term: "",
	icd_code: "",
	active_disease: false,
};

const ICD_TYPE = {
	ICD10: "ICD10",
	ICD9CM: "ICD9CM",
	OPERATING_ICD9CM: "OPERATING_ICD9CM"
}

const STYLE = {
	label: {
		fontWeight: "bold",
		whiteSpace: "nowrap"
	}
}

SubICDDetail.defaultProps = {
	id: "",
	controller: null,
	inputSize: "large",
	isReadOnly: false,
	initialData: DEFAULT_DIAGNOSIS,
	isShowDeleteButton: true,
	hideActiveDisease: false,
	icdValue: DEFAULT_DIAGNOSIS,
	marginTop: "12px",
	marginBottom: "12px",
	showSubType: false,
	isIPDProvisional: false,
	labelFitContent: false,
	isShowDetail: false,
	onSelected: () => { },
	onDetailChange: () => { },
	onErased: () => { },
	onDelete: () => { },
};

SubICDDetail.propTypes = {
	id: PropTypes.any,
	controller: PropTypes.object,
	// size => ["mini", "small", "large", "big", "huge", "massive"]
	inputSize: PropTypes.string,
	isReadOnly: PropTypes.bool,
	initialData: PropTypes.object,
	isShowDeleteButton: PropTypes.bool,
	hideActiveDisease: PropTypes.bool,
	icdValue: PropTypes.object,
	marginTop: PropTypes.string,
	marginBottom: PropTypes.string,
	/** onSelected and onDetailChange work together */
	showSubType: PropTypes.bool,
	isIPDProvisional: PropTypes.bool,
	labelFitContent: PropTypes.bool,
	isShowDetail: PropTypes.bool,
	onSelected: PropTypes.func,
	onDetailChange: PropTypes.func,
	onErased: PropTypes.func,
	onDelete: PropTypes.func,
};

export default SubICDDetail;
