import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, forwardRef, useMemo, useCallback } from "react";
import { Accordion, Form, Dropdown, Icon, Label, Button, Modal, List, Input, Message, Popup } from "semantic-ui-react";

import _ from "react-lib/compat/lodashplus";
import moment from "moment";
import { toast } from "react-toastify";
import { Column, RowInfo } from "react-table-6";

// Common
import CardLayout from "react-lib/apps/common/CardLayout";
import PureReactTable from "react-lib/apps/common/PureReactTable";
import EditorBoxColumn, { ChangeHandler } from "react-lib/appcon/common/EditorBoxColumn";
import { formatPrice, formatComma } from "react-lib/apps/common/PureReactTable";

// Interface
import { BillTransactionIPDItemDetail } from "react-lib/apis/master/apps/INF/BillTransactionIPDItem"

// Utils
import * as utils from "react-lib/utils";
import { useIntl } from "react-intl";
import {
  formatDate,
  formatDatetime,
  formatDateToYYYYMMDD,
} from "react-lib/utils/dateUtils";

const AccordionTitle = React.memo(({ name = "", isActive = true, onClick = () => {} }) => {
	return (
		<Accordion.Title style={{ border: "none", width: "fit-content" }} active={isActive} onClick={onClick}>
			<label style={{ fontWeight: "bold" }}>{name}</label>
			<Icon name="dropdown" />
		</Accordion.Title>
	);
});

const FormLabel = ({ name, value, isRequired = true, weight = 16 }) => {
	return (
    <Form.Field className={isRequired ? "required" : ""} width={weight}>
      <label style={{ minWidth: "max-content" }}>{name}</label>
      <Label style={STYLE.LABEL_INFO} size="large">
        {value || "\u00A0"}
      </Label>
    </Form.Field>
  );
};

const FormDropdown = forwardRef(
	({ name, text, options, onChange = (event, data) => {}, isRequired = true, weight = 16 }, ref) => {
		return (
      <Form.Field className={isRequired ? "required" : ""} width={weight}>
        <label style={{ minWidth: "max-content" }}>
          {text}
        </label>
        <Dropdown
				  className="inline-label"
          selection
          fluid
					size="small"
          ref={ref ? ref : null}
          name={name}
          options={options}
          style={{ width: "100%", fontSize: "1em" }}
          onChange={onChange}
        />
      </Form.Field>
    );
	}
);

const PersonalInfo = ({ data, showDetail, onToggle }) => {
  const intl = useIntl();
	const handleAccordion = (event, data) => {
		onToggle();
	};

	return (
		<>
			<AccordionTitle name={intl.formatMessage({ id: "ข้อมูลส่วนบุคคล" })}isActive={showDetail} onClick={handleAccordion} />
			<Accordion.Content style={{ marginLeft: "16px", paddingBottom: "0.5em" }} active={showDetail}>
				<Form>
					<Form.Group inline>
						<Form.Field style={{ width: "23%" }}>
							<FormLabel name={"HN"} value={data.hn ? data.hn : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "3%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={intl.formatMessage({ id: "ชื่อ" })}value={data.patient_name ? data.patient_name : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "3%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={intl.formatMessage({id: "Encounter ID/รหัสเบิก"})} value={data.encounter_id ? data.encounter_id : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "3%" }} />
						<Form.Field style={{ width: "12%" }}>
							<FormLabel name={"AN"} value={data.an ? data.an : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "12%" }}>
							<FormLabel name={intl.formatMessage({ id: "เพศ" })}value={data.gender_name ? data.gender_name : "-"} />
						</Form.Field>
					</Form.Group>

					<Form.Group inline style={{ marginTop: "16px" }}>
						<Form.Field style={{ width: "23%" }}>
							<FormLabel
								name={intl.formatMessage({ id: "เอกสารระบุตัวตน" })}								value={data.identity_document_type ? data.identity_document_type : "-"}
							/>
						</Form.Field>
						<Form.Field style={{ width: "3%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={intl.formatMessage({ id: "เลขบัตร" })}value={data.identity_document_no ? data.identity_document_no : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "3%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={intl.formatMessage({ id: "วันเดือนปีเกิด" })}value={data.birthdate ? data.birthdate : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "3%" }} />
						<Form.Field style={{ width: "24%" }}>
							<FormLabel name={intl.formatMessage({ id: "สถานภาพสมรส" })}value={data.marriage_name ? data.marriage_name : "-"} />
						</Form.Field>
					</Form.Group>

					<Form.Group inline style={{ marginTop: "16px" }}>
						<Form.Field style={{ width: "23%" }}>
							<FormLabel name={intl.formatMessage({ id: "จังหวัด" })}value={data.province_name ? data.province_name : "-"} isRequired={false} />
						</Form.Field>
						<Form.Field style={{ width: "3%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"อำเภอ/เขต"} value={data.district_name ? data.district_name : "-"} isRequired={false} />
						</Form.Field>
						<Form.Field style={{ width: "3%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={intl.formatMessage({ id: "สัญชาติ" })}value={data.nationality_name ? data.nationality_name : "-"} />
						</Form.Field>
					</Form.Group>
				</Form>
			</Accordion.Content>
		</>
	);
};

const ConfirmSentClaim = React.memo(
  ({ editData, onClose = () => {}, onConfirm = (staffCode) => {} }) => {
    const intl = useIntl();

    const [errorMessage, setErrorMessage] = useState("");
    const staffCodeRef = useRef();

    const generateListItem = (items) => {
      const newArr = [];
      let index = 0;
      for (const k in items) {
        if (items.hasOwnProperty(k)) {
          newArr.push(
            <List.Item key={index} as={"li"}>
              {EDIT_TYPE_STRING[k]}
            </List.Item>
          );
          index++;
        }
      }
      return newArr;
    };

    const handleConfirm = (event, data) => {
      if (staffCodeRef) {
        const staffCode = staffCodeRef.current.inputRef.current.value;
        if (staffCode === "") {
          setErrorMessage("กรุณาระบุรหัสพนักงาน");
          return;
        }
        setErrorMessage("");
        onConfirm(staffCode);
      }
    };

    return (
      <CardLayout
        titleText={intl.formatMessage({ id: "ยืนยันข้อมูลการส่งเบิก" })}        headerColor="orange"
        toggleable={false}
        closeable={true}
        onClose={onClose}
      >
        {errorMessage !== "" && <Message error>{errorMessage}</Message>}

        <Form style={{ marginLeft: "16px", marginRight: "16px" }}>
          {!_.isEmpty(editData) && (
            <Form.Group inline style={{ marginBottom: "16px" }}>
              <Form.Field width={6}>
                <label>{intl.formatMessage({ id: "รายการที่แก้ไข" })}</label>
              </Form.Field>
              <Form.Field width={10}>
                <List as={"ul"}>{generateListItem(editData)}</List>
              </Form.Field>
            </Form.Group>
          )}
          <Form.Group inline style={{ marginBottom: "16px" }}>
            <Form.Field width={6}>
              <label>
                กรุณาระบุรหัสพนักงาน<span>*</span>
              </label>
            </Form.Field>
            <Form.Field width={10}>
              <Input fluid ref={staffCodeRef} />
            </Form.Field>
          </Form.Group>
          <Form.Group inline style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button color={"green"} type={"submit"} onClick={handleConfirm}>
              ตกลง
            </Button>
          </Form.Group>
        </Form>
      </CardLayout>
    );
  }
);

const ActionColumn = React.memo(
  ({
    editing,
    onSave,
    onCancel,
    onEdit,
  }: {
    editing: boolean;
    onSave: () => void;
    onCancel: () => void;
    onEdit: () => void;
  }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "2px" }}>
        {editing ? (
          <>
            <Button
              color="green"
              size="mini"
              style={{ padding: "0.5rem 0.75rem" }}
              onClick={onSave}
            >
              บันทึก
            </Button>
            <Button
              color="red"
              size="mini"
              style={{ padding: "0.5rem 0.75rem", marginLeft: "0.5rem" }}
              onClick={onCancel}
            >
              ยกเลิก
            </Button>
          </>
        ) : (
          <Button color="yellow" size="mini" style={{ padding: "0.5rem 0.75rem" }} onClick={onEdit}>
            แก้ไข
          </Button>
        )}
      </div>
    );
  }
);

const InvoiceColumns = (editedCell, setEditedCell, loadInvoiceItem) =>  ([
	{ Header: "ID", accessor: "id", width: 80 },
	{
		Header: "วันเวลาที่ใช้",
		width: 180,
		Cell: props => {
			const data = props.original;
			return <label>{data.service_date ? formatDatetime(moment(data.service_date)) : ""}</label>;
		},
	},
	{ Header: "หมวดค่าใช้จ่าย", style: { whiteSpace: "nowrap" }, accessor: "bill_group", width: 240 },
	{ Header: "รหัสรายการ", accessor: "lc_code", width: 120 },
	{ Header: "รายการ", accessor: "description", width: 220 },
	{ Header: "ปริมาณ", accessor: "qty", width: 80,
	  Cell: props => {
		console.log(props.original)
		console.log(editedCell)

		if (editedCell.id === props.original.id && editedCell.key === "qty") {
			return (<input
				autoFocus
				style={{minWidth: 0, width: "100%", color: isNaN(editedCell.value) ? "red" : "black" }}
				value={editedCell.value}
				onChange={(e) => {
					setEditedCell({
						id: editedCell.id,
						key: "qty",
						value: e.target.value
					})
				}}
				onBlur={async (e) => {
					if (!isNaN(editedCell.value)) {
						const [r, err, n] = await BillTransactionIPDItemDetail.patch({
							pk: props.original.id,
							data: {
								qty: editedCell.value
							}
						})
						if (err) { console.log(e, n); }
					}
					loadInvoiceItem()
					setEditedCell({
						id: null,
						key: null,
						value: null
					})
				}}
			/>)
		} else {
			return (<div
				onClick={(e) => {
					setEditedCell({
						id: props.original.id,
						key: "qty",
						value: ""
					})
				}}>
				{props.original.qty}
			</div>)
		}

	  }
	},
	{ Header: "ราคา(หน่วย)", accessor: "unit_price", width: 120, Cell: row => formatPrice(row.value) },
	{ Header: "ราคา", accessor: "charge_amount", width: 120, Cell: row => formatPrice(row.value) },
	{ Header: "Diag ID", accessor: "diagnosis_sequence", width: 100 },
	{ Header: "ID หัตถการ", accessor: "procedure_sequence", width: 100 },
	{ Header: "CSMBS Type", accessor: "bill_group_cs", width: 140 },
	{ Header: "STD Code", accessor: "std_code", width: 140 },
	{ Header: "ประเภทการเบิก", accessor: "claim_cat", width: 120 },
	{ Header: "อัตราการเบิก(หน่วย)", accessor: "claim_unit_price", width: 160, Cell: row => formatPrice(row.value) },
	{ Header: "ยอดเบิก", accessor: "claim_amount", width: 140, Cell: row => formatPrice(row.value) },
]);

let selectedData = {};
const CardSentClaimInformationDetail = props => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  // State for Accordion
  const [togglePersonalInFo, setTogglePersonalInFo] = useState(true);
  const [toggleAdmitInfo, setToggleAdmitInfo] = useState(true);
  const [toggleDiagnosisInfo, setToggleDiagnosisInFo] = useState(true);
  const [toggleTreatmentInfo, setToggleTreatmentInFo] = useState(true);
  const [toggleInvoiceInfo, setToggleInvoiceInfo] = useState(true);
  const [toggleSentInfo, setToggleSentInfo] = useState(true);
  // State for choices
  const [choicesSentClaimType, setChoicesSentClaimType] = useState([]);
  const [choicesAdmitType, setChoicesAdmitType] = useState([]);
  const [choicesAdmitSource, setChoicesAdmitSource] = useState([]);
  const [choicesPreDischarge, setChoicesPreDischarge] = useState([]);
  const [choicesDischarge, setChoicesDischarge] = useState([]);
  const [choicesDivision, setChoicesDivision] = useState([]);
  // State for detail
  const [detail, setDetail] = useState({});
  const [diagnosisInfo, setDiagnosisInfo] = useState<any[]>([]);
  const [procedureInfo, setProcedureInfo] = useState<any[]>([]);
  const [invoiceInfo, setInvoiceInfo] = useState([]);
  const [editedCell, setEditedCell] = useState({ id: null, key: null, value: null });
  // Edit
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<Record<string, any> | null>(null);
  const [selectedProcedure, setSelectedProcedure] = useState<Record<string, any> | null>(null);
  // Use ref
  const sentClaimTypeRef = useRef();
  const admitTypeRef = useRef();
  const admitSourceRef = useRef();
  const preDischargeRef = useRef();
  const dischargeRef = useRef();
  const divisionRef = useRef();

  /** Handle confirm modal */
  const showConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const hideConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  /** Method for Accordion */
  const handlePersonalInfo = () => {
    setTogglePersonalInFo(!togglePersonalInFo);
  };

  const handleAdmitInfo = () => {
    setToggleAdmitInfo(!toggleAdmitInfo);
  };

  const handleDiagnosisInfo = () => {
    setToggleDiagnosisInFo(!toggleDiagnosisInfo);
  };

  const handleTreatmentInfo = () => {
    setToggleTreatmentInFo(!toggleTreatmentInfo);
  };

  const handleInvoiceInfo = () => {
    setToggleInvoiceInfo(!toggleInvoiceInfo);
  };

  const handleSentInfo = () => {
    setToggleSentInfo(!toggleSentInfo);
  };
  /********************** */
  /** Get choices */
  const getSentClaimType = async () => {
    const choices = await props.controller.choicesSentClaimType();
    if (!isMounted.current) {
      return;
    }
    setChoicesSentClaimType(choices);
    setDefaultDropdownValue(sentClaimTypeRef, choices, 0);
  };

  const getAdmitType = async () => {
    const choices = await props.controller.choicesAdmitOrderType();
    if (!isMounted.current) {
      return;
    }
    setChoicesAdmitType(choices);
    setDefaultDropdownValue(admitTypeRef, choices, 0);
  };

  const getAdmitSource = async () => {
    const choices = await props.controller.choicesAdmitSource();
    if (!isMounted.current) {
      return;
    }
    setChoicesAdmitSource(choices);
    setDefaultDropdownValue(admitSourceRef, choices, 0);
  };

  const getPreDischargeType = async () => {
    const choices = await props.controller.choicesPreDischargeType();
    if (!isMounted.current) {
      return;
    }
    setChoicesPreDischarge(choices);
    setDefaultDropdownValue(preDischargeRef, choices, 0);
  };

  const getDischargeType = async () => {
    const choices = await props.controller.choicesDischargeType();
    if (!isMounted.current) {
      return;
    }
    setChoicesDischarge(choices);
    setDefaultDropdownValue(dischargeRef, choices, 0);
  };

  const getDivision = async () => {
    const choices = await props.controller.choicesDivision();
    if (!isMounted.current) {
      return;
    }
    setChoicesDivision(choices);
    setDefaultDropdownValue(divisionRef, choices, 0);
  };

  // Initial value
  const setDefaultDropdownValue = (targetRef, choices, targetIndex) => {
    if (targetRef && targetRef.current.state.value === "") {
      if (choices.length > 0) {
        targetRef.current.state.value = choices[targetIndex].value;
      }
    }
  };
  /********************** */
  const loadDiagnosisInfo = async () => {
    let items: any[] = [];
    const [data, error] = await props.controller.loadDiagnosisInfo(props.transactionId);
    if (data) {
      items = data.items;
    }

    items = items.map((item) => ({
      ...item,
      diagnosis_date: formatDate(moment(item.diagnosis_date)),
    }));

    setDiagnosisInfo(items);
  };

  const loadProcedureInfo = async () => {
    let items: any[] = [];
    const [data, error] = await props.controller.loadProcedureInfo(props.transactionId);
    if (data) {
      items = data.items;
    }

    items = items.map((item) => ({
      ...item,
      start_date: formatDatetime(moment(item.start_date)),
      end_date: formatDatetime(moment(item.end_date)),
    }));

    setProcedureInfo(items);
  };

  const loadInvoiceItem = async () => {
    let items = [];
    const [data, error] = await props.controller.loadInvoiceItems(props.transactionId);
    if (data) {
      items = data.items;
    }
    setInvoiceInfo(items);
  };

  /** Initial detail */
  const initialDetail = async () => {
    const [data, error] = await props.controller.loadBillTransactionDetail(props.transactionId);
    // console.log("....... data: ", data, ", error: ", error);
    // Initial UI
    if (data) {
      setDetail(data);
      sentClaimTypeRef.current.state.value = data.submit_type;
      admitTypeRef.current.state.value = data.admit_type;
      admitSourceRef.current.state.value = data.admit_source;
      preDischargeRef.current.state.value = data.predischarge_condition;
      dischargeRef.current.state.value = data.discharge_status;
      divisionRef.current.state.value = parseInt(data.department);
    }
    // Initial choices
    getSentClaimType();
    getAdmitType();
    getAdmitSource();
    getPreDischargeType();
    getDischargeType();
    getDivision();
    // Initial Table
    loadDiagnosisInfo();
    loadProcedureInfo();
    loadInvoiceItem();
  };
  /********************** */
  /** Handle all dropdown */
  const handleDropdownChange = (event, data) => {
    if (!data) {
      return;
    }

    const targetItem = findItem(data.options, data.value);
    if (targetItem) {
      selectedData = {
        ...selectedData,
        [data.name]: targetItem,
      };
    }
  };

  const findItem = (items, value) => {
    const result = items.find((item) => {
      if (item.value === value) {
        return true;
      }
      return false;
    });

    return result;
  };

  /** Handle "บันทึกข้อมูลการส่งเบิก" button */
  const handleSentClaimSave = async () => {
    const formateDate = (datetime: string) => {
      const [date, time] = datetime.split(" ") || [];
      return datetime ? `${formatDateToYYYYMMDD(date)}T${time}:00` : "";
    };

    const editedDiagnosis = diagnosisInfo.flatMap((item) => {
      const isEditing = selectedDiagnosis?.id === item.id;
      const data = isEditing ? selectedDiagnosis : item;

      return item.editing && !isEditing
        ? [{ ...data, diagnosis_date: formatDateToYYYYMMDD(item.diagnosis_date) }]
        : [];
    });
    const editedProcedure = procedureInfo.flatMap((item) => {
      const isEditing = selectedProcedure?.id === item.id;
      const data = isEditing ? selectedProcedure : item;
      return item.editing && !isEditing
        ? [
            {
              ...data,
              start_date: formateDate(item.start_date),
              end_date: formateDate(item.end_date),
            },
          ]
        : [];
    });

    if (_.isEmpty(selectedData) && !editedDiagnosis.length && !editedProcedure.length) {
      return;
    }
    // Start loading
    setLoading(true);
    let data = {};
    for (const k in selectedData) {
      if (selectedData.hasOwnProperty(k)) {
        data = { ...data, [k]: selectedData[k].value };
      }
    }
    // Update bill transaction
    const promiseArr: any[] = [];

    if (!_.isEmpty(selectedData)) {
      promiseArr.push(props.controller.updateBillTransaction(props.transactionId, data));
    }

    if (editedDiagnosis.length) {
      promiseArr.push(
        ...editedDiagnosis.map((item) => props.controller.updateDiagnosis(item.id, item))
      );
    }

    if (editedProcedure.length) {
      promiseArr.push(
        ...editedProcedure.map((item) => props.controller.updateProcedure(item.id, item))
      );
    }

    let error = null;
    const response = await Promise.all(promiseArr);


    if (response.some((item) => item[1])) {
      error = response.map((item) => item[1]);
    }

    if (error) {
      toast.error("บันทึกข้อมูลการส่งเบิกไม่สำเร็จ!");
    } else {
      toast.success("บันทึกข้อมูลการส่งเบิกสำเร็จ");
      // Clear data
      selectedData = {};
    }

    handleEditDiagnosis(null, true)();
    handleEditProcedure(null, true)();
    // Stop loading
    setLoading(false);
  };

  /** Handle "ยืนยันข้อมูลการส่งเบิก" button */
  const handleSentClaimConfirm = async (staffCode) => {
    hideConfirmModal();
    if (staffCode === "") {
      return;
    }

    // Start loading
    setLoading(true);
    // Get token with staff code
    const [tokenData, tokenError] = await props.controller.getUserTokenize(staffCode);
    if (tokenData) {
      const token = tokenData.token;
      // Prepare data
      let data = {
        confirmed: true,
        confirmed_by: token,
      };
      for (const k in selectedData) {
        if (selectedData.hasOwnProperty(k)) {
          data = { ...data, [k]: selectedData[k].value };
        }
      }
      // Update bill transaction
      const [billData, billError] = await props.controller.updateBillTransaction(
        props.transactionId,
        data
      );
      // Stop loading
      setLoading(false);
      if (billData) {
        props.onCompleted();
        props.onClose();
        return;
      }
    }
    toast.error("การยืนยันข้อมูลการส่งเบิกไม่สำเร็จ!");
  };

  useEffect(() => {
    if (props.controller && props.transactionId) {
      // Load detail
      initialDetail();
    }
  }, [props.controller, props.transactionId]);

  useEffect(() => {
    selectedData = {};

    return () => {
      isMounted.current = false;
    };
  }, []);

  // Callback
  const handleGetTrProps = useCallback((state: any, rowInfo: RowInfo) => {
    const original = rowInfo?.original;

    return {
      style: {
        backgroundColor: original && !original?.valid ? "rgba(241, 184, 184, 1)" : "",
      },
    };
  }, []);

  const handleGetTdPropsDiagnosis = useCallback(
    (state: any, rowInfo: RowInfo, column: Column) => {
      const original = rowInfo?.original;

      return {
        style: {
          backgroundColor:
            original && original?.id === selectedDiagnosis?.id && column.Header !== ""
              ? "rgb(255, 255, 204)"
              : "",
        },
      };
    },
    [selectedDiagnosis]
  );

  const handleGetTdPropsProcedure = useCallback(
    (state: any, rowInfo: RowInfo, column: Column) => {
      const original = rowInfo?.original;

      return {
        style: {
          backgroundColor:
            original && original?.id === selectedProcedure?.id && column.Header !== ""
              ? "rgb(255, 255, 204)"
              : "",
        },
      };
    },
    [selectedProcedure]
  );

  const handleChangeValue = useCallback(
    (items: any[], detail: { key: "ICD10" | "ICD9CM"; data: any; index: number }) => {
      const { data, index, key } = detail;

      items[index]["editing"] = true;

      const keys = ["code", "procedure_term", "diagnosis_term", "medical_term"];

      const indexOfName = keys.indexOf(data.name);

      if (indexOfName >= 0) {
        const termKey = key === "ICD10" ? "diagnosis_term" : "procedure_term";

        items[index].code = data.data?.icdcode || "";
        items[index][termKey] = data.data?.icdterm || "";
        items[index].medical_term = data.data?.medterm || "";

        props.setProp("searchedItemListWithKey", {
          ...props.search,
          [`${key}Code_SCID_${index}`]: [],
          [`${key}MedTerm_SCID_${index}`]: [],
          [`${key}Term_SCID_${index}`]: [],
        });
        return;
      }

      items[index][data.name] = data.value;
    },
    []
  );

  const handleChangeDiagnosis: ChangeHandler<string> = useCallback(
    (e, data) => {
      const items = [...diagnosisInfo];
      const { index } = data;

      if (typeof index === "number" && data.name) {
        handleChangeValue(items, { data, index, key: "ICD10" });

        setDiagnosisInfo(items);
      }
    },
    [diagnosisInfo]
  );

  const mapIcd10 = useCallback(
    (key: string) => (items: any[]) => {
      return items.map((item) => ({
        key: item.icd10_id,
        value: item[key],
        text: item[key],
      }));
    },
    []
  );

  const handleEditDiagnosis = useCallback(
    (data: Record<string, any> | null, reEditing = false) =>
      () => {
        const items = diagnosisInfo.map((item) =>
          item.id === selectedDiagnosis?.id
            ? selectedDiagnosis
            : { ...item, editing: reEditing ? false : item.editing }
        );

        setDiagnosisInfo(items);
        setSelectedDiagnosis(data);
      },
    [selectedDiagnosis, diagnosisInfo]
  );

  const handleSaveDiagnosis = useCallback(() => {
    const items = diagnosisInfo.map((item) => ({
      ...item,
      // 12/12/2562
      diagnosis_date: /^\d{2}\/\d{2}\/\d{4}$/g.test(item.diagnosis_date) ? item.diagnosis_date : "",
    }));

    setDiagnosisInfo(items);
    setSelectedDiagnosis(null);
  }, [diagnosisInfo]);

  const handleChangeProcedure: ChangeHandler<string> = useCallback(
    (e, data) => {
      const items = [...procedureInfo];
      const { index } = data;

      if (typeof index === "number" && data.name) {
        handleChangeValue(items, { data, index, key: "ICD9CM" });

        setProcedureInfo(items);
      }
    },
    [procedureInfo]
  );

  const handleEditProcedure = useCallback(
    (data: Record<string, any> | null, reEditing = false) =>
      () => {
        const items = procedureInfo.map((item) =>
          item.id === selectedProcedure?.id
            ? selectedProcedure
            : { ...item, editing: reEditing ? false : item.editing }
        );

        setProcedureInfo(items);
        setSelectedProcedure(data);
      },
    [selectedProcedure, procedureInfo]
  );

  const handleSaveProcedure = useCallback(() => {
    const regex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;

    const items = procedureInfo.map((item) => ({
      ...item,
      // 12/12/2562 12:12
      start_date: regex.test(item.start_date) ? item.start_date : "",
      end_date: regex.test(item.end_date) ? item.end_date : "",
    }));

    setProcedureInfo(items);
    setSelectedProcedure(null);
  }, [procedureInfo]);

  // Columns for Diagnosis Information
  const DiagnosisColumns = useMemo(() => {
    return [
      { Header: "ID", accessor: "id", width: 70 },
      {
        Header: "Date*",
        width: 135,
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="date"
              value={original.diagnosis_date}
              name="diagnosis_date"
              index={index}
              editing={selectedDiagnosis?.id === original.id}
              onChange={handleChangeDiagnosis}
            />
          );
        },
      },
      {
        Header: "แพทย์ผู้วินิจฉัย",
        accessor: "doctor_full_name",
        width: 200,
        Cell: ({ original, index }: any = {}) => {
          return `${original.doctor_full_name}`;
        },
      },
      {
        Header: "รหัสแพทย์ผู้วินิจฉัย*",
        width: 140,
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="input"
              value={original.dr_code}
              name="dr_code"
              index={index}
              editing={selectedDiagnosis?.id === original.id}
              onChange={handleChangeDiagnosis}
            />
          );
        },
      },
      {
        Header: "Diagnosis Type*",
        accessor: "dx_type",
        width: 200,
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="dropdown"
              value={Number(original.dx_type)}
              options={props.diagnosisTypeOptions}
              name="dx_type"
              index={index}
              editing={selectedDiagnosis?.id === original.id}
              onChange={handleChangeDiagnosis}
            />
          );
        },
      },
      {
        Header: "Medical Term*",
        accessor: "medical_term",
        width: 190,
        Cell: ({ original, index }: any = {}) => {
          return original.medical_term;
        },
      },
      {
        Header: "Code*",
        accessor: "code",
        width: 110,
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="searchbox"
              onEvent={props.onEvent}
              type="ICD10Code"
              id={`SCID_${index}`}
              name="code"
              index={index}
              value={original.code}
              searchedItemListWithKey={props.searchedItemListWithKey}
              defaultText={original.code}
              valueKey="icdcode"
              editing={selectedDiagnosis?.id === original.id}
              mapOptions={mapIcd10("icdcode")}
              onChange={handleChangeDiagnosis}
            />
          );
        },
      },
      {
        Header: "Diagnosis Term*",
        accessor: "diagnosis_term",
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="searchbox"
              onEvent={props.onEvent}
              type="ICD10Term"
              id={`SCID_${index}`}
              name="diagnosis_term"
              index={index}
              value={original.diagnosis_term}
              searchedItemListWithKey={props.searchedItemListWithKey}
              defaultText={original.diagnosis_term}
              valueKey="icdterm"
              editing={selectedDiagnosis?.id === original.id}
              mapOptions={mapIcd10("icdterm")}
              onChange={handleChangeDiagnosis}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "_action",
        width: 130,
        Cell: ({ original, index }: any = {}) => {
          return (
            <ActionColumn
              editing={selectedDiagnosis?.id === original.id}
              onSave={handleSaveDiagnosis}
              onEdit={handleEditDiagnosis({ ...original })}
              onCancel={handleEditDiagnosis(null)}
            />
          );
        },
      },
    ];
  }, [
    handleChangeDiagnosis,
    handleSaveDiagnosis,
    handleEditDiagnosis,
    props.searchedItemListWithKey,
    props.diagnosisTypeOptions,
    selectedDiagnosis,
  ]);

  // Columns for Treatment Information
  const ProcedureColumns = useMemo(
    () => [
      { Header: "ID", accessor: "id", width: 70 },
      {
        Header: "วัน-เวลาที่เริ่ม*",
        width: 190,
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="datetime"
              value={original.start_date}
              name="start_date"
              index={index}
              editing={selectedProcedure?.id === original.id}
              onChange={handleChangeProcedure}
            />
          );
        },
      },
      {
        Header: "วัน-เวลาที่สิ้นสุด*",
        width: 190,
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="datetime"
              value={original.end_date}
              name="end_date"
              index={index}
              editing={selectedProcedure?.id === original.id}
              onChange={handleChangeProcedure}
            />
          );
        },
      },
      {
        Header: "รหัสแพทย์ผู้วินิจฉัย*",
        width: 140,
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="input"
              value={original.dr_code}
              name="dr_code"
              index={index}
              editing={selectedProcedure?.id === original.id}
              onChange={handleChangeProcedure}
            />
          );
        },
      },
      { Header: "แผนกที่ทำหัตถการ", accessor: "location", width: 170 },
      { Header: "Medical Term", accessor: "medical_term", width: 190 },
      {
        Header: "Code*",
        accessor: "code",
        width: 110,
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="searchbox"
              onEvent={props.onEvent}
              type="ICD9CMCode"
              id={`SCID_${index}`}
              name="code"
              index={index}
              value={original.code}
              searchedItemListWithKey={props.searchedItemListWithKey}
              defaultText={original.code}
              valueKey="icdcode"
              editing={selectedProcedure?.id === original.id}
              mapOptions={mapIcd10("icdcode")}
              onChange={handleChangeProcedure}
            />
          );
        },
      },
      {
        Header: "Procedure Term*",
        accessor: "procedure_term",
        Cell: ({ original, index }: any = {}) => {
          return (
            <EditorBoxColumn
              boxType="searchbox"
              onEvent={props.onEvent}
              type="ICD9CMTerm"
              id={`SCID_${index}`}
              name="procedure_term"
              index={index}
              value={original.procedure_term}
              searchedItemListWithKey={props.searchedItemListWithKey}
              defaultText={original.procedure_term}
              valueKey="icdterm"
              editing={selectedProcedure?.id === original.id}
              mapOptions={mapIcd10("icdterm")}
              onChange={handleChangeProcedure}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "_action",
        width: 130,
        Cell: ({ original, index }: any = {}) => {
          return (
            <ActionColumn
              editing={selectedProcedure?.id === original.id}
              onSave={handleSaveProcedure}
              onEdit={handleEditProcedure({ ...original })}
              onCancel={handleEditProcedure(null)}
            />
          );
        },
      },
    ],
    [
      handleChangeProcedure,
      handleSaveProcedure,
      handleEditProcedure,
      selectedProcedure,
      props.searchedItemListWithKey,
    ]
  );

  return (
    <CardLayout
      titleText={props.cardTitle}
      headerColor={"green"}
      loading={loading}
      toggleable={false}
      closeable={true}
      onClose={props.onClose}
    >
      <Modal open={openConfirmModal} size={"tiny"} onClose={hideConfirmModal}>
        <ConfirmSentClaim
          editData={selectedData}
          onClose={hideConfirmModal}
          onConfirm={handleSentClaimConfirm}
        />
      </Modal>

      <Accordion.Content style={{ paddingBottom: "0.5rem" }}>
        <Form style={{ marginLeft: "16px" }}>
          <Form.Group inline>
            <Form.Field width={4}>
              <FormDropdown
                ref={sentClaimTypeRef}
                name={EDIT_TYPE.SENT_CLAIM_TYPE}
                text={intl.formatMessage({ id: "ประเภทการส่งเบิก" })}                options={choicesSentClaimType}
                onChange={handleDropdownChange}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Accordion.Content>

      {/* Personal Information */}
      <PersonalInfo data={detail} showDetail={togglePersonalInFo} onToggle={handlePersonalInfo} />

      {/* Admit Information */}
      <AccordionTitle
        name={intl.formatMessage({ id: "ข้อมูลการ Admit" })}        isActive={toggleAdmitInfo}
        onClick={handleAdmitInfo}
      />
      <Accordion.Content
        style={{ marginLeft: "16px", paddingBottom: "0.5em" }}
        active={toggleAdmitInfo}
      >
        <Form>
          <Form.Group inline>
            <Form.Field style={{ width: "23%" }}>
              <FormDropdown
                ref={admitTypeRef}
                name={EDIT_TYPE.ADMIT_TYPE}
                text={intl.formatMessage({ id: "ประเภทการ Admit" })}                options={choicesAdmitType}
                onChange={handleDropdownChange}
              />
            </Form.Field>
            <Form.Field style={{ width: "3%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormDropdown
                ref={admitSourceRef}
                name={EDIT_TYPE.ADMIT_SOURCE}
                text={intl.formatMessage({ id: "รับ Admit จาก" })}                options={choicesAdmitSource}
                onChange={handleDropdownChange}
              />
            </Form.Field>
            <Form.Field style={{ width: "3%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "เริ่ม Admit" })}                value={detail.admit_dt ? formatDatetime(moment(detail.admit_dt)) : "-"}
              />
            </Form.Field>
            <Form.Field style={{ width: "3%" }} />
            <Form.Field style={{ width: "24%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "สิ้นสุด" })}                value={detail.discharge_dt ? formatDatetime(moment(detail.discharge_dt)) : "-"}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline style={{ marginTop: "16px" }}>
            <Form.Field style={{ width: "23%" }}>
              <FormDropdown
                ref={preDischargeRef}
                name={EDIT_TYPE.PRE_DISCHARGE_TYPE}
                text={intl.formatMessage({ id: "สภาพก่อนจำหน่าย" })}                options={choicesPreDischarge}
                onChange={handleDropdownChange}
              />
            </Form.Field>
            <Form.Field style={{ width: "3%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormDropdown
                ref={dischargeRef}
                name={EDIT_TYPE.DISCHARGE_TYPE}
                text={intl.formatMessage({ id: "สถานะการจำหน่าย" })}                options={choicesDischarge}
                onChange={handleDropdownChange}
              />
            </Form.Field>
            <Form.Field style={{ width: "3%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "น้ำหนัก(kg.)" })}                value={detail.admit_weight ? detail.admit_weight : "-"}
              />
            </Form.Field>
            <Form.Field style={{ width: "3%" }} />
            <Form.Field style={{ width: "24%" }}>
              <FormDropdown
                ref={divisionRef}
                name={EDIT_TYPE.DIVISION}
                text={intl.formatMessage({ id: "แผนกที่รักษา" })}                options={choicesDivision}
                onChange={handleDropdownChange}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Accordion.Content>

      {/* Diagnosis Information */}
      <AccordionTitle
        name={intl.formatMessage({ id: "ข้อมูลการวินิจฉัย" })}        isActive={toggleDiagnosisInfo}
        onClick={handleDiagnosisInfo}
      />
      <Accordion.Content active={toggleDiagnosisInfo}>
        <PureReactTable
          className="--rt-tbody-overflow-y-hidden"
          style={{ height: "205px" }}
          showPagination={false}
          minRows={5}
          defaultPageSize={1000}
          resizable={false}
          data={diagnosisInfo}
          columns={DiagnosisColumns}
          getTrProps={handleGetTrProps}
          getTdProps={handleGetTdPropsDiagnosis}
        />
      </Accordion.Content>

      {/* Treatment Information */}
      <AccordionTitle
        name={intl.formatMessage({ id: "ข้อมูลการทำหัตถการ" })}        isActive={toggleTreatmentInfo}
        onClick={handleTreatmentInfo}
      />
      <Accordion.Content active={toggleTreatmentInfo}>
        <PureReactTable
          className="--rt-tbody-overflow-y-hidden"
          style={{ height: "205px" }}
          showPagination={false}
          minRows={5}
          defaultPageSize={1000}
          data={procedureInfo}
          resizable={false}
          columns={ProcedureColumns}
          getTrProps={handleGetTrProps}
          getTdProps={handleGetTdPropsProcedure}
        />
      </Accordion.Content>

      {/* Invoice Information */}
      <AccordionTitle
        name={intl.formatMessage({ id: "ข้อมูล Invoice" })}        isActive={toggleInvoiceInfo}
        onClick={handleInvoiceInfo}
      />
      <Accordion.Content active={toggleInvoiceInfo}>
        <Form style={{ marginLeft: "16px", marginBottom: "16px" }}>
          <Form.Group inline>
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={"Invoice No."}
                value={detail.invoice_no ? detail.invoice_no : "-"}
                isRequired={false}
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "วันที่ออก" })}                value={detail.invoice_date ? detail.invoice_date : "-"}
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "เวลา" })}                value={detail.invoice_time ? detail.invoice_time : "-"}
                isRequired={false}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <PureReactTable
          className="--rt-tbody-overflow-y-hidden"
          style={{ height: "200px" }}
          showPagination={false}
          minRows={5}
          defaultPageSize={1000}
          data={invoiceInfo}
          columns={InvoiceColumns(editedCell, setEditedCell, loadInvoiceItem)}
        />

        <Form style={{ marginLeft: "16px", marginTop: "16px" }}>
          <Form.Group inline>
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ส่วนลด" })}                value={detail.total_discount_price ? formatComma(detail.total_discount_price) : "-"}
                isRequired={false}
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ยอดชำระทั้งหมด" })}                value={detail.total_amount_price ? formatComma(detail.total_amount_price) : "-"}
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ค่า RW" })}                value={detail.rw_value ? formatComma(detail.rw_value) : "-"}
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ค่า ADJRW" })}                value={detail.adj_rw_value ? formatComma(detail.adj_rw_value) : "-"}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline style={{ marginTop: "16px" }}>
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ค่า DRG" })}                value={detail.drg_value ? formatComma(detail.drg_value) : "-"}
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ยอดเบิกตาม DRG" })}                value={detail.total_drg_price ? formatComma(detail.total_drg_price) : "-"}
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ยอดเบิกนอก DRG" })}                value={detail.total_non_drg_price ? formatComma(detail.total_non_drg_price) : "-"}
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ยอดเบิกทั้งหมด" })}                value={
                  detail.total_send_claim_price ? formatComma(detail.total_send_claim_price) : "-"
                }
                isRequired={false}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Accordion.Content>

      {/* Sent Information */}
      <AccordionTitle
        name={intl.formatMessage({ id: "ข้อมูลการเบิก" })}        isActive={toggleSentInfo}
        style={{ width: "fit-content" }}
        onClick={handleSentInfo}
      />
      <Accordion.Content style={{ marginLeft: "16px" }} active={toggleSentInfo}>
        <label style={{ textDecoration: "underline" }}>{intl.formatMessage({ id: "รายละเอียดยอดเบิก" })}</label>
        <Form style={{ marginTop: "16px" }}>
          <Form.Group inline>
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ค่าห้องและค่าอาหาร" })}                value={
                  detail.total_send_claim_01_price
                    ? formatComma(detail.total_send_claim_01_price)
                    : "-"
                }
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ค่าธรรมเนียมแพทย์" })}                value={
                  detail.total_send_claim_df_price
                    ? formatComma(detail.total_send_claim_df_price)
                    : "-"
                }
              />
            </Form.Field>
            <Form.Field style={{ width: "4%" }} />
            <Form.Field style={{ width: "22%" }}>
              <FormLabel
                name={intl.formatMessage({ id: "ค่ารักษาอื่น" })}                value={
                  detail.total_send_claim_other_price
                    ? formatComma(detail.total_send_claim_other_price)
                    : "-"
                }
                isRequired={false}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Accordion.Content>

      <Form.Group
        inline
        style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem" }}
      >
        <Button
          color={"green"}
          style={{ marginRight: "16px" }}
          type={"button"}
          onClick={handleSentClaimSave}
        >
          บันทึกข้อมูลการส่งเบิก
        </Button>
        <Button color={"blue"} type={"button"} onClick={showConfirmModal}>
          ยืนยันข้อมูลการส่งเบิก
        </Button>
      </Form.Group>
    </CardLayout>
  );
};

const STYLE = {
	LABEL_INFO: {
		width: "100%",
		textAlign: "center",
	},
};

const EDIT_TYPE = {
	SENT_CLAIM_TYPE: "submit_type",
	ADMIT_TYPE: "admit_type",
	ADMIT_SOURCE: "admit_source",
	PRE_DISCHARGE_TYPE: "predischarge_condition",
	DISCHARGE_TYPE: "discharge_status",
	DIVISION: "department",
};

const EDIT_TYPE_STRING = {
	[EDIT_TYPE.SENT_CLAIM_TYPE]: "ประเภทการส่งเบิก",
	[EDIT_TYPE.ADMIT_TYPE]: "ประเภทการ Admit",
	[EDIT_TYPE.ADMIT_SOURCE]: "รับ Admit จาก",
	[EDIT_TYPE.PRE_DISCHARGE_TYPE]: "สภาพก่อนจำหน่าย",
	[EDIT_TYPE.DISCHARGE_TYPE]: "สถานะการจำหน่าย",
	[EDIT_TYPE.DIVISION]: "แผนกที่รักษา",
};

CardSentClaimInformationDetail.defaultProps = {
  cardTitle: "รายละเอียดข้อมูลส่งเบิก",
  controller: null,
  transactionId: null,
	searchedItemListWithKey: {},
	diagnosisTypeOptions: [],
  onCompleted: () => {},
  onClose: () => {},
  onEvent: () => {},
  setProp: () => {}
};

CardSentClaimInformationDetail.propTypes = {
  cardTitle: PropTypes.string,
  controller: PropTypes.object,
  transactionId: PropTypes.number,
  onCompleted: PropTypes.func,
  onClose: PropTypes.func,
  onEvent: PropTypes.func,
  searchedItemListWithKey: PropTypes.object,
  diagnosisTypeOptions: PropTypes.array,
  setProp: PropTypes.func,
};

export default React.memo(CardSentClaimInformationDetail);
