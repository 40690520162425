import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Label,
  Dimmer,
  Loader,
  Grid,
  Input,
  TextArea,
  Divider,
  Button,
  Dropdown,
  Modal,
  Icon,
  Image,
  Header,
  Placeholder
} from "semantic-ui-react";

import PropTypes from "prop-types";
import CardLayout from "../common/CardLayout";
import _ from "../../compat/lodashplus";
import WebcamComponent from "../common/WebcamComponent";
import ModalEditImage from "../common/ModalEditImage";
import ModalUploadFile from "../common/ModalUploadFile";
import ModalTemplateFile from "../common/ModalTemplateFile";
import { useIntl } from "react-intl";

const ImageDetail = ({ dataURI, selectedImage, updateImage, removeImage }: any) => {
  const inputRef = useRef<any>(null);
  const intl = useIntl();

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current.ref) {
      if (selectedImage && selectedImage.description) {
        inputRef.current.ref.current.value = selectedImage.description;
      } else {
        inputRef.current.ref.current.value = "";
      }
    }
  }, [selectedImage]);

  return (
    <>
      <Header as="h3">Edit Image Description</Header>
      <Form>
        <Form.Field>{!!dataURI && <Image src={dataURI} size="large" centered onError={(i: any) => (i.target.src = "static/images/picture.gif")} />}</Form.Field>
        <Form.Field>
          <label>{intl.formatMessage({ id: "รายละเอียด" })}</label>
          <TextArea ref={inputRef} placeholder={intl.formatMessage({ id: "ใส่รายละเอียดรูปภาพ" })}rows={10} />
        </Form.Field>

        <Form.Group inline>
          <Form.Button
            color={"green"}
            onClick={() => {
              updateImage({
                selectedImage: selectedImage,
                imageDataURI: dataURI,
                detail: inputRef.current.ref.current.value,
              });
            }}
          >
            {" "}
            Update
          </Form.Button>
          <Form.Button
            color={"red"}
            onClick={() => {
              removeImage({
                selectedImage: selectedImage,
                imageDataURI: dataURI,
                detail: inputRef.current.ref.current.value,
              });

              // controller.removeImageId(selectedImage.image);
            }}
          >
            {" "}
            Remove
          </Form.Button>
        </Form.Group>
      </Form>
    </>
  );
};

const ImageGrid = ({ id, image, onSelectedImage }: any) => {
  return (
    <Grid.Column
      key={id}
      onClick={() => {
        onSelectedImage(image);
      }}
    >
      <Image src={image.image} />
    </Grid.Column>
  );
};

const ImageThreeGrid = ({ chunk, onSelectedImage }: any) => {
  return (
    <>
      {_.map(chunk, image => {
        return (
          <ImageGrid
            key={image.id}
            id={image.id}
            image={image}
            onSelectedImage={onSelectedImage}
          />
        );
      })}
    </>
  );
};

const ImageListGrid = ({ images, onSelectedImage }: any) => {
  let rowCount = 0;
  let imagesChunk = []; // e.g. [[1,2,3], [4,5,6], [7,8]]

  if (images && images.hasOwnProperty("total")) {
    rowCount = Math.ceil(images.total / 3);
  }

  if (rowCount === 0) {
    return <div> </div>;
  } else {
    imagesChunk = _.chunk(images.items, 3);
  }

  return (
    <Grid
      columns={3}
      color="gray"
      celled
      style={{ overflow: "auto", maxHeight: 690 }}
    >
      {_.map(imagesChunk, (chunk, idx) => {
        return (
          <Grid.Row key={idx}>
            <ImageThreeGrid chunk={chunk} onSelectedImage={onSelectedImage} />
          </Grid.Row>
        );
      })}
    </Grid>
  );
};

const CardImageGallery = (props: any) => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSet, setImageSet] = useState<any>({ items: [], total: 0 });
  const [templateUrl, setTemplateUrl] = useState([]);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [openWebcam, setOpenWebCam] = useState(false);

  const [openModalEditImage, setOpenModalEditImage] = useState(false);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);
  const [openModalOpenTemplete, setOpenModalOpenTemplete] = useState(false);


  const [editedDataURI, setEditDataURI] = useState<string | null>(null);
  const [undoState, setUndoState] = useState<any>(null);
  const [editedImageData, setEditedImageData] = useState<any>(null)

  useEffect(() => {
    if (props.controller) {
      let a = getImageSet();
      a.then(() => {
        props.finishLoading();
      });
    }
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if(!props.controller){
      setImageSet(props.imageData.data || {items: []});
    }
  }, [props.imageData]) 

  useEffect(() => {
    if (selectedImage !== null) {
      let selected = imageSet?.items.find((item: any) => item.id === selectedImage.id);
      if (selected) {
        setSelectedImage(selected);
      }
    }
  }, [imageSet]);

  const getImageSet = async () => {
    if (
      props.controller &&
      props.patientData &&
      props.patientData.EMR &&
      props.patientData.EMR.emr_id
    ) {
      setIsLoading(true);
      const [data, error] = await props.controller.getMedicalRecordGallery({
        emrId: props.patientData.EMR.emr_id
      });

      if (isMounted.current) {
        if (error) {
          setIsLoading(false);
          return;
        }

        if (data) {
          // Handle DAta
          setImageSet(data);
          setIsLoading(false);

          // Update data
          const findImage = data.items?.find((item: any) => item.id === editedImageData?.id);
          setEditedImageData(findImage || null);
        }
      }
    }
  };

  const getTemplateGalleryImage = async () => {
    if (props.controller) {
      setIsLoading(true);
      const [data, error] = await props.controller.getTemplateGalleryImage();

      if (isMounted.current) {
        if (error) {
          setIsLoading(false);
          return;
        }

        if (data) {
          // Handle DAta
          setImageSet(data);
          setIsLoading(false);
        }
      }
    }
  };



  const handleRemoveImage = async ({ selectedImage, imageDataURI, detail }: any) => {
    setUndoState({ selectedImage, imageDataURI, detail });
    if(props.controller){
      await props.controller.removeImage({
        selectedImage: selectedImage,
        imageDataURI: imageDataURI,
        detail: detail
      });
      if (isMounted.current) {
        getImageSet();
        setSelectedImage(null);
      }
    }else{
      props.onRemoveImage?.(selectedImage, imageDataURI, detail)
    }
  };

  const handleUpdateImage = async ({ selectedImage, imageDataURI, detail }: any) => {
    if(props.controller){
      await props.controller.updateImageDetail({
        selectedImage: selectedImage,
        imageDataURI: imageDataURI,
        detail: detail
      });
      if (isMounted.current) {
        getImageSet();
      }
    }else{
      props.onUpdateImage?.(selectedImage, imageDataURI, detail)
    }
    // setSelectedImage(null)
  };

  const handleCancelImage = () => {
    if (!editedDataURI) {
      setSelectedImage(null);
    } else {
      setSelectedImage(editedImageData)
    }
  }

  const handleWebCamComponentClose = () => {
    setOpenWebCam(false);
  }

  const handleTakePhoto = () => {
    setOpenWebCam(true);
  };

  const handleUpLoadPhoto = () => {
    setOpenModalUploadFile(true);
  };

  const handleOpenTemplate = async () => {

    const [data, error] = await props.controller.getTemplateGalleryImage();
    if (isMounted.current) {
      if (error) {
        return;
      }
      if (data) {
        // Handle DAta
        if (data && data.items && data.total) {
          let urlList = data.items.filter((item: any) => item.active).map((item: any) => { return { image: item.image, name: item.name, id: item.id } })
          setTemplateUrl(urlList);
          setOpenModalOpenTemplete(true)
        } else {
          alert("ไม่มี Templated รูป กรุณาใส่รูปก่อนการใช้งาน")
        }
      }
    }

  }

  const handleEditImage = (dataURI: string) => {
    setEditedImageData(selectedImage);
    setEditDataURI(dataURI);
  };

  const handleUndoState = async () => {
    await props.controller.updateImageDetail(undoState);
    if (isMounted.current) {
      setUndoState(null);
      getImageSet();
    }
  };

  const handleSaveMultipleNewImage = async (imgSrcList: any) => {
    for (var i = 0; i < imgSrcList.length; i++) {
      if (imgSrcList[i]) {
        // imgSrc is base64 encoded string
        let data = {
          emr: props.patientData.EMR.emr_id,
          image: imgSrcList[i],
          description: "",
          items: imageSet.items
        };

        setIsLoading(true)
        const [response, error] = await props.controller.postMedicalRecordGallery({
          emrId: props.patientData.EMR.emr_id,
          data
        });

        if (error) {
          console.log("error Need break ", error);
          // Break loop 
          setIsLoading(false)
          break;
        }
      }
    }
    if (isMounted.current) {
      getImageSet()
      setIsLoading(false)
    }
  }

  const handleSaveNewImage = async (imgSrc: any) => {
    // imgSrc is base64 encoded string
    let data = {
      emr: props.patientData.EMR.emr_id,
      image: imgSrc,
      description: "",
      items: imageSet?.items
    };
    if(props.controller){
      const [response, error] = await props.controller.postMedicalRecordGallery({
        emrId: props.patientData.EMR.emr_id,
        data
      });

      if (isMounted.current) {
        if (error) {
          console.log("error", error);
        } else {
          setSelectedImage(response);
          setOpenModalEditImage(true);
          getImageSet();
        }
      }
    }else{
      props.onCreateImage?.(imgSrc,)
        
      //   (data: any) => {
      //      // onSuccess function
      //   setOpenModalEditImage(true);
      //   setSelectedImage(data);
      // }
    }
  };

  console.log("cardImageGallery props: ", selectedImage?.id )
  return (
    <>
      <CardLayout
        titleText="Gallery"
        closeable={props.closeable}
        toggleable={props.toggleable}
        hideHeaderIcon={props.hideHeaderIcon}
        defaultBackground={props.defaultBackground}
        hideTopRightText={false}
        headerColor="blue"
        onClose={props.onClose}
      >
        <Dimmer active={isLoading} inverted>
          <Loader content="Loading Image Gallery" />
        </Dimmer>

        <ModalEditImage
          image={selectedImage}
          openEditModal={openModalEditImage}
          onCloseModal={setOpenModalEditImage}
          cancelImage={handleCancelImage}
          editedImage={handleEditImage}
          backgroundColor="white"
        />

        <ModalUploadFile 
          openUploadFileModal={openModalUploadFile} 
          onCloseModal={setOpenModalUploadFile} 
          onUploadFile={handleSaveNewImage} 
          isCheckSize={true} 
        />

        <ModalTemplateFile
          openTempleteFile={openModalOpenTemplete}
          onCloseModal={setOpenModalOpenTemplete}
          onUploadMultipleFile={handleSaveMultipleNewImage}
          templateUrl={templateUrl}
        />

        <WebcamComponent
          open={openWebcam}
          onClose={handleWebCamComponentClose}
          setNewImage={handleSaveNewImage}
          isCheckSize={true}
        />

        <Grid divided>
          <Grid.Column width={9}>
            <Header as="h3">Image List(s)</Header>
            <ImageListGrid
              onSelectedImage={(img: any) => {
                setOpenModalEditImage(true);
                setSelectedImage(img);
              }}
              images={imageSet}
            />
            <Button color="blue" 
              // disabled={!props.patientData?.EMR?.emr_id} 
              onClick={handleTakePhoto}>
              <Icon name="camera" />
              Take Photo
            </Button>
            <Button color="blue" 
              // disabled={!props.patientData?.EMR?.emr_id} 
              onClick={handleUpLoadPhoto}>
              <Icon name="upload" />
              Upload
            </Button>
            {props.showTemplete && (
              <Button color="purple" disabled={!props.patientData?.EMR?.emr_id} onClick={handleOpenTemplate}>
                <Icon name="image outline" />
                Template
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={7}>
            {!!selectedImage && selectedImage?.hasOwnProperty("image") && (
              <ImageDetail
                removeImage={handleRemoveImage}
                updateImage={handleUpdateImage}
                dataURI={editedDataURI}
                selectedImage={selectedImage}
              />
            )}

            {!selectedImage && (
              <>
                <Header as="h4">No Picture Selected</Header>
                <Form>
                  {/* <Form.Field>
                    <Image src={"static/images/picture.gif"} centered />
                  </Form.Field> */}

                  {undoState && (
                    <Form.Field style={{ textAlign: "center" }}>
                      <Button color="purple" onClick={handleUndoState}>
                        {" "}
                        Undo Remove{" "}
                      </Button>
                    </Form.Field>
                  )}
                </Form>
              </>
            )}
          </Grid.Column>
        </Grid>
      </CardLayout>
    </>
  );
};

CardImageGallery.defaultProps = {
  closeable: true,
  toggleable: false,
  hideHeaderIcon: false,
  showTemplete: false,
  defaultBackground: true,
  controller: null,
  patientData: null,
  onClose: () => { },
  finishLoading: () => { },
  onCreateImage: () => { },
  onGetImage: () => { },
  onRemoveImage: () => { },
  onUpdateImage: () => { },
};

CardImageGallery.propTypes = {
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  showTemplete: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  defaultBackground: PropTypes.bool,
  controller: PropTypes.object,
  patientData: PropTypes.object,
  onClose: PropTypes.func,
  finishLoading: PropTypes.func,
  onCreateImage: PropTypes.func,
  onGetImage: PropTypes.func,
  onRemoveImage: PropTypes.func,
  onUpdateImage: PropTypes.func,
  imageData: PropTypes.any
};

export default React.memo(CardImageGallery);
